<template>
    <div>
        <v-container fluid>
             <v-row>
                <v-col>
                    <v-tabs v-model="tab" background-color="primary" dark show-arrows next-icon="mdi-menu-right" prev-icon="mdi-menu-left">
                        <v-tab v-for="(item,index) in symbols" :key="index">
                            <span>{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                    <v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                      <v-data-table v-if="!loading" :headers="krakenCoinSwapsHeaders" :items="markets" dense hide-default-footer disable-pagination>
                          <template v-slot:body="{ items }">
                              <tbody>
                                  <tr v-for="(item,index) in items" :key="index">
                                        <td>{{item.symbol}}</td>                                  
                                        <td class="text-right">{{common.formatNum(item.price)}}</td>
                                        <td class="text-right">{{common.formatNum(item.openInterest.toFixed(2))}}</td>
                                        <td class="text-right">{{common.formatNum(item.openInterestCoinValue.toFixed(2))}}</td>
                                        <td class="text-right">{{common.formatNum(item.openInterestValue.toFixed(2))}}</td>
                                        <td class="text-right">{{common.formatNum(item.volume.toFixed(2))}}</td>
                                        <td class="text-right">{{common.formatNum(item.volumeCoinValue.toFixed(2))}}</td>
                                        <td class="text-right">{{common.formatNum(item.volumeValue.toFixed(2))}}</td>
                                        <td>{{common.formatTime(item.timestamp)}}</td>
                                  </tr>
                                  <tr v-if="markets.length == 0">
                                      <td class="text-center" colspan="17">No Data Available</td>
                                  </tr>
                              </tbody>
                          </template>
                      </v-data-table>
                      <v-pagination class="mt-5" v-if="totalPage > 0" v-model="pageNo" :length="totalPage" :total-visible="10" circle @input="getMarket(symbols[tab])" prev-icon="mdi-menu-left" next-icon="mdi-menu-right"></v-pagination>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                tab:0,
                hour:24,
                symbols:[],
                markets:[],
                krakenCoinSwapsHeaders: [
                    { text: 'Symbol', sortable: false },
                    { text: 'Price', sortable: false, align:'end' },
                    { text: 'Open Int', sortable: false, align:'end' },
                    { text: 'Coin Value', sortable: false, align:'end' },
                    { text: '$', sortable: false, align:'end' },
                    { text: '24 Hr Vol',sortable: false, align:'end' },
                    { text: 'Coin Value', sortable: false, align:'end' },
                    { text: '$', sortable: false, align:'end' },
                    { text: 'Time', sortable: false }
                ],
                pageNo:1,
                pageSize:20,
                totalPage:0,
                loading:false
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            symbols(val){
                if(val.length > 0){
                    this.getMarket(val[0]);
                }
            },
            tab(val){
                this.pageNo = 1;
                let smybol = this.symbols[val]
                this.getMarket(smybol);
            }
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getSymols();
        },
        created() {
           
        },
        methods: {
            getMarket(symbol){
                this.loading = true;
                this.markets = [];
                this.totalPage = 0;
                let api = config.baseUrl + "/admin/private/getDynamicMarket?exchange=kraken&symbol="+ symbol +"&category=futures&quoteCurrency=usd&hour="+this.hour+"&pageNo="+(this.pageNo -1) + "&pageSize=" + this.pageSize;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.loading = false;
                        this.markets = data.result.markets;
                        this.totalPage = data.result.totalPage;
                    }else{
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                        this.loading = false;
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', 'Internal Service Exception');
                    this.loading = false;
                })
            },
            getSymols(){
                let api = config.baseUrl + '/admin/private/getMarketSymbols?exchange=kraken&category=futures&quoteCurrency=usd';
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.symbols = data.result;
                    }else{
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', 'Internal Service Exception');
                })
            },
           
        }
    }
</script>