<template>
    <div>
        <v-container fluid class="pt-0 mt-5"> 
            <v-row>
                <v-col>
                    <v-alert :dismissible="false" class="deep-purple white--text" dark border="left" icon="fas fa-chart-area">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                            <v-col cols="10" class="pa-0">
                                <span>Kraken Markets</span>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="px-0 ml-3">
                    <v-tabs v-model="type" color="indigo" show-arrows next-icon="mdi-menu-right" prev-icon="mdi-menu-left">
                        <v-tab href="#krakenSpot" class="text-none">Kraken Spot</v-tab>
                        <v-tab href="#krakenBTCSpot" class="text-none">Kraken BTC Spot</v-tab>
                        <v-tab href="#krakenCoinSwaps" class="text-none">Kraken Coin Swaps</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="type">
                         <v-tab-item value="krakenSpot">
                            <KrakenSpot></KrakenSpot>
                        </v-tab-item>
                        <v-tab-item value="krakenBTCSpot">
                            <KrakenBTCSpot></KrakenBTCSpot>
                        </v-tab-item>
                         <v-tab-item value="krakenCoinSwaps">
                            <KrakenCoinSwaps></KrakenCoinSwaps>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import {  mapGetters } from "vuex";
    import KrakenSpot from './KrakenSpot';
    import KrakenBTCSpot from './KrakenBTCSpot';
    import KrakenCoinSwaps from './KrakenCoinSwaps';
    export default {
        data() {
            return {
                type:"result",
                all:true,
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        components:{
            KrakenSpot,
            KrakenBTCSpot,
            KrakenCoinSwaps
        },
        watch: {
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
        },
        created() {

        },
        methods: {
            
        }
    }
</script>
